.react-calendar {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: white;
  line-height: 1.125em;

  &__viewContainer {
    flex: 1;
  }

  & button:not(.mui) {
    margin: 0;
    border: 0;
    outline: none;

    // &:enabled:hover {
    //   cursor: pointer;
    // }
  }

  &__tile {
    border-bottom: 1px solid #bfbfbf !important;
    border-right: 1px solid #bfbfbf !important;

    max-width: 100%;
    padding: 10px 6.6667px;
    background-color: white;
    text-align: center;
    font: inherit;
    font-size: 0.833em;

    &:disabled {
      background-color: #f0f0f0;
      color: #ababab;
    }

    // &:enabled:hover,
    // &:enabled:focus {
    //   background-color: lightslategray;
    //   color: white;
    // }

    & > abbr {
      background-color: rgba(255, 255, 255, 0.75);
    }

    &--now {
      background: var(--primary-color);
      color: white;

      // &:enabled:focus,
      // &:enabled:hover {
      //   background: var(--primary-color-light);
      // }

      & > abbr {
        background-color: rgba(var(--primary-color), 0.75);
      }
    }

    // &--hasActive {
    //   background: var(--primary-color);

    //   &:enabled:focus,
    //   &:enabled:hover {
    //     background: var(--primary-color-light);
    //   }
    // }

    // &--active {
    //   background: var(--secondary-color);
    //   color: white;

    //   &:enabled:focus,
    //   &:enabled:hover {
    //     background: var(--secondary-color-light);
    //   }
    // }
  }

  &__month-view {
    height: 100%;

    & > div {
      height: 100%;

      & > div {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    }

    &__weekdays {
      text-align: center;
      text-transform: uppercase;
      font: inherit;
      font-size: 0.75em;
      font-weight: bold;
      border: 1px solid #bfbfbf;
      border-top: 0px;

      &__weekday {
        padding: 0.5em;
      }
    }

    &__days {
      flex: 1;
      border-left: 1px solid #bfbfbf;

      &__day {
        padding: 8px;
        position: relative;
        display: flex;
        // justify-content: center;
        align-items: center;
        min-height: 75px;

        // height: calc(100% / 6);
        // overflow-y: auto !important;

        & > abbr {
          position: absolute;
          top: 0;
          left: 0;
          padding: 5px;
        }

        &--weekend {
          color: #d10000;
        }
      }
    }

    &__weekNumbers .react-calendar__tile {
      display: flex;
      align-items: center;
      justify-content: center;
      font: inherit;
      font-size: 0.75em;
      font-weight: bold;
    }
  }

  &__year-view,
  &__decade-view,
  &__century-view {
    height: 100%;
    border-left: 1px solid #bfbfbf;

    &__months,
    &__years,
    &__decades {
      height: 100%;
    }
  }

  &--doubleView {
    width: 100%;

    & .react-calendar__viewContainer {
      display: flex;
      margin: -0.5em;

      & > * {
        width: 50%;
        margin: 0.5em;
      }
    }
  }

  &__navigation {
    display: flex;
    height: 35px;
    border: 1px solid #bfbfbf;

    & button {
      min-width: 44px;
      background: none;

      &:disabled {
        background-color: #f0f0f0;
      }

      &:enabled:hover,
      &:enabled:focus {
        background-color: #e6e6e6;
      }
    }
  }

  &--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }

  &,
  & *,
  & *:before,
  & *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  &__month-view__days__day--neighboringMonth,
  &__decade-view__years__year--neighboringDecade,
  &__century-view__decades__decade--neighboringCentury {
    color: black;
    background-color: #e6e6e6;

    & > abbr {
      background-color: rgba(var(--primary-color), 0.75);
    }
  }

  &__year-view .react-calendar__tile,
  &__decade-view .react-calendar__tile,
  &__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }

  &__month-view__days__day--neighboringMonth:disabled,
  &__decade-view__years__year--neighboringDecade:disabled,
  &__century-view__decades__decade--neighboringCentury:disabled {
    color: #cdcdcd;
  }
}
