.flex {
  display: flex;

  &Cols {
    @extend .flex;
    flex-direction: column;
  }

  &Between {
    @extend .flex;
    justify-content: space-between;
    align-items: center;
  }
}

.card {
  position: relative;
  overflow: visible;
  padding-top: 0;
  @media screen and (max-width: 600px) {
    margin: 10px 0;
  }
}

.label {
  opacity: 0.65;
  @media screen and (max-width: 600px) {
    display: none;
  }
}

.welcomeWidget {
  background: var(--primary-color-light);
  color: var(--secondary-color);
  display: flex;
  position: relative;
  height: 100%;
  @media screen and (max-width: 1280px) {
    flex-direction: column;
  }
}

.container {
  align-items: flex-start;
  padding: 32px;
  text-align: left;

  @media screen and (max-width: 900px) {
    padding: 24px;
  }

  &Welcome {
    @extend .container;
    min-height: 250px;

    @media screen and (min-width: 1280px) {
      padding-right: 0px;
    }

    @media screen and (max-width: 1280px) {
      padding-bottom: 0px;
    }

    @media screen and (max-width: 960px) {
      min-height: 0px;
    }
  }

  &Card {
    @extend .container;
    box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
    height: 100%;

    &Chart {
      @extend .containerCard;
      display: flex;
      justify-content: space-around;

      @media screen and (max-width: 1280px) {
        flex-direction: column;
        align-items: center;
      }
    }
  }

  &Setup {
    @extend .container;
    margin-top: 16px;
    border-radius: 4px;
    height: 100%;

    &Vision {
      @extend .containerSetup;
      background: linear-gradient(135deg, rgba(228, 226, 91, 0.2), rgba(167, 78, 0, 0.2));
    }

    &Strategy {
      @extend .containerSetup;
      background: linear-gradient(135deg, rgba(91, 228, 148, 0.2), rgba(0, 167, 6, 0.2));
    }

    &Execution {
      @extend .containerSetup;
      background: linear-gradient(135deg, rgba(91, 116, 228, 0.2), rgba(0, 64, 167, 0.2));
    }
  }
}

.list {
  max-height: 450px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cfcfcf;
    border-radius: 20px;
  }
}

.imgContainer {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 900px) {
    padding: 24px;
  }
}

.linearProgress {
  height: 8px;
  border-radius: 4px;
}

.itemTitle {
  display: flex;
  font-weight: bold;
  align-items: center;

  &Progress {
    @extend .itemTitle;
    color: rgba(41, 11, 55, 0.792);
  }

  &WithBg {
    @extend .itemTitle;
    color: white;
    padding: 8px;
    border-radius: 8px;

    &Rock {
      @extend .itemTitleWithBg;
      background: linear-gradient(135deg, rgba(64, 96, 241, 0.8), rgba(1, 56, 145, 0.8));
    }

    &Todo {
      @extend .itemTitleWithBg;
      background: linear-gradient(135deg, rgba(241, 64, 117, 0.8), rgba(145, 1, 39, 0.8));
    }

    &Objective {
      @extend .itemTitleWithBg;
      background: linear-gradient(135deg, rgba(232, 149, 25, 0.8), rgba(188, 115, 7, 0.8));
    }

    &WeeklyTarget {
      @extend .itemTitleWithBg;
      background: linear-gradient(135deg, rgba(25, 232, 35, 0.8), rgba(9, 173, 6, 0.8));
    }
  }
}

.item {
  display: flex;
  align-items: flex-start;
  padding: 4px 0;
  border-radius: 4px;

  &Doc {
    @extend .item;
    margin-top: 4px;
  }

  &Nested {
    @extend .item;
    margin-left: 16px;
  }
  &Placeholder {
    @extend .item;
    opacity: 0.5;
  }
}

.itemLabel {
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 4px;
  color: white;
  &Rock {
    @extend .itemLabel;
    font-weight: bold;
    color: rgba(26, 57, 192, 0.9);
    background: rgba(64, 96, 241, 0.5);
    margin-right: 8px;
    font-size: 10px;
  }
  &SC {
    @extend .itemLabel;
    font-weight: bold;
    color: rgba(25, 117, 167, 0.9);
    background: rgba(64, 188, 241, 0.5);
    margin-right: 8px;
    font-size: 10px;
  }

  &PastDue {
    @extend .itemLabel;
    font-weight: bold;
    color: rgba(167, 30, 25, 0.9);
    background: rgba(213, 213, 213, 0.5);
    margin-left: auto;
    font-size: 10px;
  }
  &Todo {
    @extend .itemLabel;
    font-weight: bold;
    color: rgba(101, 25, 167, 0.9);
    background: rgba(124, 40, 197, 0.5);
    margin-right: 8px;
    font-size: 10px;
  }
  &Issue {
    @extend .itemLabel;
    font-weight: bold;
    color: rgba(167, 25, 61, 0.9);
    background: rgba(241, 64, 91, 0.5);
    margin-right: 8px;
    font-size: 10px;
  }
  &WeeklyTarget {
    @extend .itemLabel;
    font-weight: bold;
    color: rgba(25, 167, 25, 0.9);
    background: rgba(64, 241, 105, 0.5);
    margin-right: 8px;
    font-size: 10px;
  }
  &HighP {
    @extend .itemLabel;
    font-weight: bold;
    color: rgba(229, 115, 115, 0.9);
    background: rgba(229, 115, 115, 0.221);
    margin-right: 8px;
    font-size: 10px;
  }
  &MedP {
    @extend .itemLabel;
    font-weight: bold;
    color: rgba(255, 183, 77, 0.9);
    background: rgba(255, 183, 77, 0.221);
    margin-right: 8px;
    font-size: 10px;
  }
  &LowP {
    @extend .itemLabel;
    font-weight: bold;
    color: rgba(129, 199, 132, 0.9);
    background: rgba(129, 199, 132, 0.221);
    margin-right: 8px;
    font-size: 10px;
  }
  &OffTrack {
    @extend .itemLabel;
    font-weight: bold;
    color: rgba(229, 115, 115, 0.9);
    background: rgba(229, 115, 115, 0.221);
    margin-right: 8px;
    font-size: 10px;
    min-width: 70px;
  }
  &OnTrack {
    @extend .itemLabel;
    font-weight: bold;
    color: rgba(129, 199, 132, 0.9);
    background: rgba(129, 199, 132, 0.221);
    margin-right: 8px;
    font-size: 10px;
    min-width: 70px;
    display: flex;
    justify-content: center;
  }
  &Complete {
    @extend .itemLabel;
    font-weight: bold;
    color: rgba(144, 164, 174, 0.9);
    background: rgba(144, 164, 174, 0.221);
    margin-right: 8px;
    font-size: 10px;
    min-width: 70px;
    display: flex;
    justify-content: center;
  }
}

.itemValue {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.itemButton {
  display: flex;
  margin-left: auto;
  align-items: center;
}

.boxShadow {
  box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
}
.wrapper {
  display: flex;
  flex-direction: column;
  padding: 32px;
  @media screen and (max-width: 900px) {
    padding: 24px;
  }
}

.progressTitle {
  text-align: center;
  padding: 0 0 16px;
  font-weight: bold;
}

.progressContainer {
  position: relative;
  display: inline-flex;
  margin: 0 auto 8px;
  color: rgb(76, 58, 120);
  max-width: 180px;
  justify-content: center;
}

.progressUnderlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  color: rgb(235, 36, 140);
}

.progressLabel {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  color: rgb(245, 149, 199);
}

.progressLegend {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  padding: 0px 8px;
  @media screen and (max-width: 1280px) {
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
  }
}

.legendText {
  text-align: center;
  font-weight: bold;
  color: rgb(138, 138, 138);
}

.value {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  &Completed {
    @extend .value;
    color: rgb(235, 36, 140);
  }
  &Total {
    @extend .value;
    color: rgb(76, 58, 120);
  }
}

.objList {
  max-height: 360px;
  overflow: auto;
  min-width: 264px;
}

.kanbanBtn {
  text-transform: none;
  opacity: 0.65;
  font-weight: 400;
}

.calendar {
  &Events {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;

    &Item {
      display: flex;
      // align-items: center;
      background-color: #e6e6e6;
      border-radius: 4px;
      border: 1px solid;
      color: black;

      &:hover {
        cursor: pointer;
        background-color: #d9d9d9;
      }

      &Text {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        margin-top: 2px;
      }
    }
  }

  &Popover {
    max-width: 350px;

    &Item {
      display: flex;
      // align-items: center;

      &Text {
        margin-top: 2px;
      }
    }
  }

  &Item {
    &Dept {
      height: fit-content;
      padding: 2px 4px;
      border-radius: 4px;
    }
  }
}
